<script>
import {ref, computed, watch} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default {
  setup(){
    const route        = useRoute();
    // const router       = useRouter();
    const store        = useStore();
    const isAdmin      = computed( () => store.state.token_type == 1);
    const country      = computed( () => {
      if(!isAdmin.value){
        return store.state.token_country;
      }
      else{
        return route.params.country;
      }
    });
    const hasContracts = store.state.countries.find(d => d.id == country.value).contracts;
    
    const contract     = computed( () => {
      if(!hasContracts || !store.state.contracts[country.value]) return null;
      return store.state.contracts[country.value].find(d => d.id == route.params.id)
    });

    // if(!contract.value){
    //     router.push({name : '404'});
    // }

    const id          = ref(contract.value ? contract.value.id : null);
    const title       = ref(contract.value ? contract.value.title : null);
    const provider    = ref(contract.value ? contract.value.provider : null);
    const state       = ref(contract.value ? contract.value.state : null);
    const type        = ref(contract.value ? contract.value.type : null);
    const amount      = ref(contract.value ? contract.value.amount : null);
    const currency    = ref(contract.value ? contract.value.currency : null);
    const items       = ref(contract.value ? contract.value.items : null);
    const diff        = ref(contract.value ? contract.value.diff : null);
    const days        = ref(contract.value ? contract.value.days : null);
    const file        = ref(null);
    const buyer       = ref(contract.value ? contract.value.buyer : null);
    const date        = ref(contract.value ? store.getters.fixDate(contract.value.contract) : null);
    const showSuccess = ref(null);
    const showError   = ref(null);
    const extraCurrency = ref(currency.value != "USD" ? currency.value : null);

    watch( contract, (conn, prevConn) => {
      if(prevConn) return;
      id          .value = conn.id 
      title       .value = conn.title 
      provider    .value = conn.provider 
      state       .value = conn.state 
      type        .value = conn.type 
      amount      .value = conn.amount 
      currency    .value = conn.currency 
      items       .value = conn.items 
      diff        .value = conn.diff 
      days        .value = conn.days 
      buyer       .value = conn.buyer 
      date        .value = store.getters.fixDate(conn.contract) 
      extraCurrency.value = currency.value != "USD" ? currency.value : null
    });

    const makeObject = () => {
      const formData = new FormData();
      formData.append("id", id.value);
      formData.append("title", title.value);
      formData.append("provider", provider.value);
      formData.append("state", state.value);
      formData.append("type", type.value);
      formData.append("amount", amount.value);
      formData.append("currency", currency.value);
      formData.append("items", items.value);
      formData.append("diff", diff.value);
      formData.append("days", days.value);
      formData.append("file", file.value.files[0]);
      formData.append("buyer", buyer.value);
      formData.append("date", date.value);
      formData.append("country", route.params.country);

      return formData;
    }

    const updateContract = e => {
      e.preventDefault();

      const obj = makeObject();

      store.dispatch("updateContract", obj)
      .then( () => {
        showSuccess.value = true;
        // clearForm();
        setTimeout(() => showSuccess.value = null, 5000)
      })
      .catch( () => {
        showError.value = true;
        setTimeout(() => showError.value = null, 5000)
      })
    }

    return {
      hasContracts,

      id,
      title,
      provider,
      state,
      type,
      amount,
      currency,
      items,
      diff,
      days,
      file,
      buyer,
      date,
      extraCurrency,

      showSuccess,
      showError,
      updateContract
    }
  }
}
</script>
<template>
  <transition name="fade">
    <p v-if="showSuccess" class="ob_alert success">El contrato se ha actualizado</p>
  </transition>
  <transition name="fade">
    <p v-if="showError" class="ob_alert danger">El contrato no se pudo actualizar</p>
  </transition>
  <form v-if="hasContracts" @submit.prevent="updateContract">
  <div class="row">
    <div class="col-sm-3">
    <p>
      <label>fecha de contratación:</label>
      <input type="date" v-model="date" required />
    </p>
    </div>
    <div class="col-sm-9">
    <p>
      <label>identificador:</label>
      <input type="text" v-model="id" disabled />
    </p>
    </div>
    </div>
    <p>
      <label>título:</label>
      <input type="text" v-model="title" required />
    </p>
    <p>
      <label>proveedor:</label>
      <input type="text" v-model="provider" />
    </p>
    <p>
      <label>comprador:</label>
      <input type="text" v-model="buyer" />
    </p>
    <div class="row">
    <div class="col-sm-6">
    <p>
      <label>estado:</label>
      <select v-model="state">
        <option value=""></option>
        <option value="planning">planeación</option>
        <option value="tender">licitación</option>
        <option value="award">adjudicacíon</option>
        <option value="contract">contratación</option>
        <option value="implementation">implementación</option>
      </select>
    </p>
    </div>
    <div class="col-sm-6">
    <p>
      <label>tipo:</label>
      <select v-model="type">
        <option value=""></option>
        <option value="open">abierto</option>
        <option value="direct">directo</option>
        <option value="selective">selectiva</option>
        <option value="limited">limitada</option>
      </select>
    </p>
    </div>
    </div>
    <div class="row">
    <div class="col-sm-9">
    <p>
      <label>monto:</label>
      <input type="number" v-model="amount" required />
    </p>
    </div>
    <div class="col-sm-3">
    <p>
      <label>moneda:</label>
      <select v-model="currency" required>
        <option v-if="extraCurrency">{{extraCurrency}}</option>
        <option>USD</option>
      </select>
    </p>
    </div>
    </div>
    <p>
      <label>productos/servicios:</label>
      <textarea v-model="items"></textarea>
    </p>
    <div class="row">
    <div class="col-sm-6">
    <p>
      <label>diferencia entre monto adjudicado y contratado:</label>
      <input type="number" v-model="diff" />
    </p>
    </div>
    <div class="col-sm-6">
    <p>
      <label>duración de la licitación (días):</label>
      <input type="number" v-model="days" />
    </p>
     </div>
    </div>
    
    <p>
      <label>archivo:</label>
      <input type="file" ref="file" />
    </p>

    
    <p>
      <input type="submit" value="actualizar contrato" />
    </p>
  </form>

  <div v-else>No puedes agregar contratos</div>

</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>